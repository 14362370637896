//
// user-variables.scss
// Use this to override Bootstrap and Around variables
//

// Example of a variable override to change body background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #f6f9fc;


$navbar-nav-link-padding-x:                 0.5rem !default;
$font-family-sans-serif:                    "Twemoji Country Flags", "Inter", sans-serif !default;
$primary:                                 #2034b6 !default;
$border-radius:                             0 !default;

$prefix:                                    ar- !default;

$font-size-root:                            0.95rem !default;
$font-size-base:                            $font-size-root !default;
$font-size-xl:                              calc(var(--#{$prefix}body-font-size) * 1.2) !default;
$font-size-lg:                              calc(var(--#{$prefix}body-font-size) * 1.1) !default;

$h1-font-size:                              $font-size-base * 2.4 !default;
$h2-font-size:                              $font-size-base * 1.9 !default;
$h3-font-size:                              $font-size-base * 1.7 !default;
